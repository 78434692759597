import Heading from "../../../components/shared/Heading/Heading";
import ProductCard from "../../../components/shared/ProductCard/ProductCard";
import { Link } from "react-router-dom";
import iProduct from "../../../components/shared/Products/Products.props";
import LottieAnimation from "../../../components/shared/LottieAnimation";

const FeaturedProducts: React.FC<{ products: iProduct[] }> = ({ products }) => {
  const prioritizedProducts = [
    ...products.filter((product) => product.isOffert),
    ...products.filter((product) => !product.isOffert),
  ].slice(0, 3);

  return (
    <div className="container mt-16">
      <Heading showDash className="pb-16 px-5 text-center md:text-left">
        Echa un vistazo a nuestra cuidada colección de ropa y accesorios de alta calidad con las últimas tendencias y estilo.
      </Heading>

      <div className="items-center justify-between sm:flex text-center text-[#EB773C]">
        <Heading isMain className="mb-0 text-center">
          Productos más destacados
        </Heading>
        <Link to="/store">
          <button className="border-2 border-[#EB773C] bg-[#EB773C] text-white text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6 md:py-3 md:px-4 rounded-full hover:bg-[#c96024] hover:border-[#c96024] transition duration-300">
            Comprar ahora
          </button>
        </Link>
      </div>

      {prioritizedProducts && prioritizedProducts.length > 0 ? (
        <div className="grid gap-7 xxl:gap-0 xl:px-20 py-10 md:grid-cols-2 lg:grid-cols-3 justify-center justify-items-center mx-auto px-4">
          {prioritizedProducts.map((product) => (
            <ProductCard
              key={product.id}
              id={product.id}
              image={Array.isArray(product.image) ? product.image : []}
              name={product.name}
              price={product.price}
              discountPrice={product.discountPrice}
              description={product.description}
              category={product.category}
              stock={product.stock}
              color={Array.isArray(product.color) ? product.color : []}
              size={Array.isArray(product.size) ? product.size : []}
              isOffert={product.isOffert || false}
              handcraft={product.handcraft || false}
            />          
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center text-center ">
          <div className='max-w-lg w-96'>
              <LottieAnimation type={'loading-discount'} loop={true} />
          </div>
          <h2 className="text-3xl font-bold text-[#EB773C] mb-4">Cargando productos</h2>
        </div>
      )}

      <div className="mt-16 h-px w-full bg-neutral-300" />
    </div>
  );
};

export default FeaturedProducts;
