import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LottieAnimation from '../../components/shared/LottieAnimation';

const NotFound: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center justify-center text-center p-6 py-24">
            <div className='-mt-44 w-full max-w-lg relative z-10'>
                <LottieAnimation type={'404'} />
            </div>
            <h2 className="text-3xl font-bold text-gray-800 mb-4 -mt-10">Página no encontrada</h2>
            <p className="text-lg text-gray-600 font-medium max-w-lg leading-relaxed text-center">
                Parece que uno de nuestros gatos traviesos estuvo jugando con los cables, ¡y esta página desapareció! Puedes regresar al inicio para explorar otras secciones.
            </p>
            <button
                onClick={() => navigate('/')}
                className="mt-6 px-8 py-3 bg-[#EB773C] text-white text-lg font-semibold rounded-lg hover:bg-[#d46834] transition-transform transform hover:scale-105"
            >
                Inicio
            </button>
        </div>
    );
};

export default NotFound;
