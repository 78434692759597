import { useForm, ValidationError } from '@formspree/react';
import Heading from '../../../components/shared/Heading/Heading';
import { FiTruck } from 'react-icons/fi';
import { TiClipboard } from 'react-icons/ti';
import { RiWhatsappFill } from "react-icons/ri";
import pattern from '../../../assets/images/background.svg';
import { FaSpinner } from 'react-icons/fa'; 
import LottieAnimation from '../../../components/shared/LottieAnimation';
import { useEffect, useState } from 'react';
import { registerUser } from '../../../services/airtableService';

const directContactInfo = [
    {
        icon: <RiWhatsappFill className="text-3xl" />,
        title: 'Escríbenos al whatsapp',
        contactLink: {
            href: 'https://wa.me/1234567890',
            title: 'Consultas por WhatsApp',
        },
    },      
    {
        icon: <FiTruck className="text-3xl" />,
        title: 'Encargos',
        contactLink: {
        href: 'mailto:priscomshop@gmail.com',
        title: 'priscomshop@gmail.com',
        },
    },
    {
        icon: <TiClipboard className="text-3xl" />,
        title: 'Información sobre tallas y productos',
        contactLink: {
        href: 'mailto:priscomshop@gmail.com',
        title: 'priscomshop@gmail.com',
        },
    },
];

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [state, handleSubmit] = useForm('mdkozaaw');

  useEffect(() => {
    if (state.succeeded) {
      registerUser({
        fullName: name,
        email: email,
      }).catch((error) =>
          console.error('Error al registrar usuario en Airtable:', error)
        );
    }
  }, [state.succeeded, name, email]);

  if (state.succeeded) {
    return (
      <div className="flex flex-col items-center justify-center text-center ">
        <div className='max-w-lg w-96'>
            <LottieAnimation type={'message'} loop={true} />
        </div>
        <h2 className="text-3xl font-bold text-[#EB773C] mb-4 -mt-20">Mensaje enviado</h2>
        <button
            onClick={() => window.location.reload()}
            className="mt-2 mb-16 px-8 py-3 bg-[#EB773C] text-white text-lg font-semibold rounded-lg hover:bg-[#d46834] transition-transform transform hover:scale-105">
            Volver a enviar un mensaje
        </button>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="mt-20 px-4 md:px-0 text-[#EB773C]">
        <Heading 
          desc="Estamos aquí para ayudarte con cualquier consulta o solicitud que tengas. Por favor, completa el formulario y nuestro equipo se pondrá en contacto contigo lo antes posible." 
          isMain 
          isCenter
        >
          ¿Cómo podemos ayudarle?
        </Heading>
      </div>

      <div className="mb-20">
        <div className="grid gap-10 lg:grid-cols-3">
          {directContactInfo.map((info) => (
            <div
              key={info.title}
              className="flex flex-col items-center justify-center text-center gap-2 px-4">
              <button className="text-[#EB773C]">
                {info.icon}
              </button>
              <h2 className="text-xl md:text-2xl font-medium">{info.title}</h2>
              <a href={info.contactLink.href} target='_blank' rel="noreferrer" className="text-[#EB773C] hover:underline">
                {info.contactLink.title}
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className='px-4 md:px-0'>
        <div
          className="max-w-96 mx-auto my-20 md:max-w-3xl rounded-3xl p-5 md:p-10 lg:p-16 shadow"
          style={{
              backgroundColor: '#808BC3',
              backgroundImage: `url(${pattern})`,
              backgroundSize: '1000px',
              backgroundRepeat: 'repeat'
          }}>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <label htmlFor="name" className="mb-2 font-semibold text-start text-white">Nombre y apellidos</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Nombre y apellidos"
                  type="text"
                  className="border border-neutral-300 bg-gray-100 placeholder:text-neutral-500 focus:outline-none focus:border-primary h-14 px-4 rounded-lg"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="email" className="mb-2 font-semibold text-start text-white">Correo electrónico</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Correo electrónico"
                  className="border border-neutral-300 bg-gray-100 placeholder:text-neutral-500 focus:outline-none focus:border-primary h-14 px-4 rounded-lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <ValidationError prefix="Email" field="email" errors={state.errors} />
              </div>

              <div className="flex flex-col">
                <label htmlFor="message" className="mb-2 font-semibold text-start text-white">Mensaje</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Escriba aquí su mensaje"
                  className="border border-neutral-300 bg-gray-100 placeholder:text-neutral-500 focus:outline-none focus:border-primary p-4 rounded-lg"
                  rows={6}
                  style={{ minHeight: '100px', maxHeight: '300px' }} 
                  required
                />
                <ValidationError prefix="Message" field="message" errors={state.errors} />
              </div>

              <button
                type="submit"
                disabled={state.submitting}
                className="mt-4 w-full bg-[#EB773C] text-white font-bold py-3 px-6 rounded-lg hover:bg-[#d46834] transition flex items-center justify-center gap-2"
              >
                {state.submitting ? (
                  <>
                    <FaSpinner className="animate-spin" />
                    Enviando...
                  </>
                ) : (
                  'Enviar mensaje'
                )}
              </button>
            </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
