import axios from "axios";

const AIRTABLE_BASE_URL = `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}`;
const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;

export interface AirtableRecord {
  id: string;
  fields: {
    Name: string;
    Description: string;
    Price: string;
    DiscountPrice: string;
    Image: Array<{ url: string }>;
    Category: string;
    Stock: string;
    Color: string[];
    Size: string[];
    IsOffert: boolean;
    Handcraft: boolean;
  };
}

export interface CategoryRecord {
  id: string;
  fields: {
    Name: string;
    Description: string;
  };
}

/**
 * Función para obtener registros de una tabla en Airtable.
 * @param table - Nombre de la tabla en Airtable
 * @returns Promise<AirtableRecord[]>
 */
export const fetchRecords = async (table: string): Promise<AirtableRecord[]> => {
  try {
    const response = await axios.get(`${AIRTABLE_BASE_URL}/${table}`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
    });

    if (!response.data.records || !Array.isArray(response.data.records)) {
      throw new Error("La respuesta de Airtable no contiene registros válidos.");
    }

    return response.data.records.map((record: any) => ({
      id: record.id,
      fields: {
        Name: record.fields.Name || "",
        Description: record.fields.Description || "",
        Price: record.fields.Price || "0",
        DiscountPrice: record.fields.DiscountPrice || "0",
        Image: Array.isArray(record.fields.Image) ? record.fields.Image : [],
        Category: record.fields.Category || "",
        Stock: record.fields.Stock || "0",
        Color: Array.isArray(record.fields.Color) ? record.fields.Color : [],
        Size: Array.isArray(record.fields.Size) ? record.fields.Size : [],
        IsOffert: record.fields.IsOffert || false,
        Handcraft: record.fields.Handcraft || false,
      },
    }));
  } catch (error) {
    console.error("Error al obtener datos de Airtable:", error);
    throw error;
  }
};

/**
 * Función para obtener el nombre de una categoría dado su ID.
 * @param categoryId - ID de la categoría
 * @returns Promise<{ id: string; name: string }>
 */
export const resolveCategory = async (categoryId: string): Promise<{ id: string; name: string }> => {
  try {
    const response = await axios.get(`${AIRTABLE_BASE_URL}/Categories/${categoryId}`, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
      },
    });

    if (!response.data || !response.data.fields) {
      throw new Error(`No se encontró la categoría con ID ${categoryId}`);
    }

    return {
      id: response.data.id,
      name: response.data.fields.Name || "Sin nombre",
    };
  } catch (error) {
    console.error(`Error al resolver categoría con ID ${categoryId}:`, error);
    throw error;
  }
};

/**
 * Función para registrar un usuario en la tabla `Users` de Airtable.
 * @param user - Información del usuario a registrar (nombre completo y correo electrónico).
 * @returns Promise<void>
 */
export const registerUser = async (user: {
  fullName: string;
  email: string;
}): Promise<void> => {
  try {
    const userId = createUserId();

    await axios.post(
      `${AIRTABLE_BASE_URL}/Users`,
      {
        fields: {
          "ID": userId,
          "Full Name": user.fullName,
          "Email Address": user.email,
          "User Type": "Contact",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${AIRTABLE_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.error("Error al registrar usuario en Airtable:", error);
    throw error;
  }
};

/**
 * Función para generar un ID único para un usuario.
 * @returns string
 */
export const createUserId = (): string => {
  const timestamp = Date.now().toString(36);
  const randomPart = Math.random().toString(36).substring(2, 8);
  return `USER-${timestamp}-${randomPart}`.toUpperCase();
};
