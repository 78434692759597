import React, { useEffect, useState } from 'react';
import ProductCard from '../../../components/shared/ProductCard/ProductCard';
import { AirtableRecord, fetchRecords, resolveCategory } from '../../../services/airtableService';
import iProduct from '../../../components/shared/Products/Products.props';

interface ProductsProps {
  onItemCount: (count: number) => void;
  selectedCategory: string;
  sortOrder: string | null;
}

const Products: React.FC<ProductsProps> = ({ onItemCount, selectedCategory, sortOrder }) => {
  const [products, setProducts] = useState<iProduct[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records: AirtableRecord[] = await fetchRecords('Products');
        const formattedProducts = await Promise.all(
          records.map(async (record) => {
            const resolvedCategory = record.fields.Category
              ? await resolveCategory(record.fields.Category)
              : { id: '', name: 'Sin categoría' };

            return {
              id: record.id,
              name: record.fields.Name,
              description: record.fields.Description,
              price: record.fields.Price,
              discountPrice: record.fields.DiscountPrice,
              image: record.fields.Image,
              category: resolvedCategory,
              stock: record.fields.Stock,
              color: record.fields.Color,
              size: record.fields.Size,
              isOffert: record.fields.IsOffert,
              handcraft: record.fields.Handcraft,
            };
          })
        );
        setProducts(formattedProducts);
      } catch (error) {
        console.error('Error al buscar productos:', error);
      }
    };

    fetchData();
  }, []);

  const filteredProducts =
    selectedCategory === 'Todos los productos'
      ? products
      : selectedCategory === 'Promoción'
      ? products.filter(
          (product) =>
            product.isOffert === true && Number(product.discountPrice) > 0
        )
      : products.filter((product) => product.category.name === selectedCategory);

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOrder === 'Menor-a-mayor') return Number(a.price) - Number(b.price);
    if (sortOrder === 'Mayor-a-menor') return Number(b.price) - Number(a.price);
    if (sortOrder === 'alfabeticamente-a-z') return a.name.localeCompare(b.name);
    if (sortOrder === 'alfabeticamente-z-a') return b.name.localeCompare(a.name);
    return 0;
  });

  useEffect(() => {
    onItemCount(sortedProducts.length);
  }, [sortedProducts, onItemCount]);

  return (
    <>
      {sortedProducts && sortedProducts.length > 0 ? (
        sortedProducts.map((product) => (
          <ProductCard
            key={product.id}
            id={product.id}
            image={product.image || []}
            name={product.name}
            price={product.price}
            discountPrice={product.discountPrice}
            description={product.description}
            category={product.category}
            stock={product.stock}
            color={product.color || []}
            size={product.size || []}
            isOffert={!!product.isOffert}
            handcraft={!!product.handcraft}
          />
        ))
      ) : (
        <>
          <div className="animate-pulse bg-gray-200 rounded-xl shadow-lg overflow-hidden mb-6 lg:w-auto">
            <div className="h-64 bg-gray-300"></div>
            <div className="p-4 space-y-4">
              <div className="h-6 bg-gray-300 rounded"></div>
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
            </div>
          </div>
          <div className="animate-pulse bg-gray-200 rounded-xl shadow-lg overflow-hidden mb-6  lg:w-auto invisible md:visible">
            <div className="h-64 bg-gray-300"></div>
            <div className="p-4 space-y-4">
              <div className="h-6 bg-gray-300 rounded"></div>
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
            </div>
          </div>
          <div className="animate-pulse bg-gray-200 rounded-xl shadow-lg overflow-hidden mb-6 w-[21rem] lg:w-auto invisible xl:visible">
            <div className="h-64 bg-gray-300"></div>
            <div className="p-4 space-y-4">
              <div className="h-6 bg-gray-300 rounded"></div>
              <div className="h-4 bg-gray-300 rounded w-3/4"></div>
              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Products;
