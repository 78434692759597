import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import LottieAnimation from '../../components/shared/LottieAnimation';
import iProduct from '../../components/shared/Products/Products.props';

const ProductDetail: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const product = state as iProduct;

  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!product) {
    return (
      <div className="flex flex-col items-center justify-center text-center p-6 py-24">
        <div className='-mt-28 max-w-lg relative z-10'>
          <LottieAnimation type={'not-found'} loop={false} />
        </div>
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Producto no encontrado</h2>
        <p className="text-lg text-gray-600 font-medium max-w-lg leading-relaxed text-center">
          Lo sentimos, no pudimos encontrar el producto que estás buscando.
        </p>
        <button
          onClick={() => navigate('/')}
          className="mt-6 px-8 py-3 bg-[#EB773C] text-white text-lg font-semibold rounded-lg hover:bg-[#d46834] transition-transform transform hover:scale-105">
          Inicio
        </button>
      </div>
    );
  }

  const handleIncrement = (stock: string) => {
    setQuantity((prev) => Math.min(prev + 1, Number(stock)));
  };
  const handleDecrement = () => setQuantity((prev) => Math.max(prev - 1, 1));

  const closeAlert = () => {
    setAlertMessage(null);
  };

  const handleBuyNow = () => {
    if (product.size?.length && !selectedSize) {
      setAlertMessage('Por favor selecciona un tamaño.');
      return;
    }

    if (product.color?.length && !selectedColor) {
      setAlertMessage('Por favor selecciona un color.');
      return;
    }

    navigate('/checkout', {
      state: {
        product,
        selectedSize: selectedSize || null,
        selectedColor: selectedColor || null,
        quantity,
      },
    });
  };

  return (
    <div className="container mx-auto px-8 pb-8">
      <div className="mb-4">
        <button
          onClick={() => navigate(-1)}
          className="bg-white border border-gray-300 p-2 rounded-full shadow hover:bg-gray-100 transition">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6 text-gray-800">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>
      </div>
      { product ? (
        <div className="flex flex-col md:flex-row items-start gap-10">
          <div className="flex-1">
            {product.image?.length > 1 ? (
              <Splide
                options={{
                  type: 'loop',
                  perPage: 1,
                  pagination: true,
                  arrows: true,
                }}>
                {product.image?.map((image, index) => (
                  <SplideSlide key={index}>
                    <img
                      src={image.url}
                      alt={`Imagen ${index + 1} de ${product.name}`}
                      className="w-full rounded object-cover"/>
                  </SplideSlide>
                ))}
              </Splide>
            ) : (
              <img
                src={product.image[0].url}
                alt={`Imagen de ${product.name}`}
                className="w-full rounded object-cover"/>
            )}
          </div>

          <div className="flex-1">
            

            {product.isOffert && Number(product.discountPrice) > 0 ? (
              <>
                <div className="flex flex-col items-start gap-2 mb-4">
                  <h1 className="text-3xl font-bold text-gray-800">{product.name}</h1>
                  <div className="flex flex-wrap gap-2 ">
                    {product.isOffert && Number(product.discountPrice) > 0 && (
                      <div className="h-auto mt-1 px-3 py-1 bg-[#EB773C] text-white text-sm font-semibold rounded shadow">
                        Promoción
                      </div>
                    )}
                    {product.handcraft && (
                      <div className="h-auto mt-1 px-3 py-1 bg-[#EB773C] text-white text-sm font-semibold rounded shadow">
                        Hecho a mano
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {product.isOffert && (
                    <div className='mb-6'>
                      <span className="text-xl font-semibold text-[#EB773C]">C${product.discountPrice}  </span>
                      <span className="text-lg text-gray-500 line-through">C${product.price}</span>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className='mb-6'>
                <h1 className="text-3xl font-bold text-gray-800 mb-4">{product.name}</h1>
                <span className="text-xl font-semibold text-gray-700 mb-6">C${product.price}</span>
              </div>
            )}

            {product?.description && (
              <p className="text-gray-600 mb-6">{product.description}</p>
            )}

            {product?.size?.length > 0 && (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Tamaños disponibles:</h3>
                <div className="flex gap-2">
                  {product.size.map((size) => (
                    <button
                      key={size}
                      onClick={() => setSelectedSize(size)}
                      className={`px-4 py-2 border rounded ${
                        selectedSize === size
                          ? 'bg-[#EB773C] text-white'
                          : 'text-gray-700 hover:bg-gray-100'
                      }`}>
                      {size}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {product?.color?.length > 0 && (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Colores disponibles:</h3>
                <div className="flex gap-3">
                  {product.color.map((color) => (
                    <button
                      key={color}
                      onClick={() => setSelectedColor(color)}
                      className={`px-4 py-2 border rounded ${
                        selectedColor === color
                          ? 'bg-[#EB773C] text-white'
                          : 'text-gray-700 hover:bg-gray-100'
                      }`}>
                      {color}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <h3 className="text-lg font-semibold text-gray-800 mb-2">Stock:</h3>
            <div className="flex gap-3">
              <p className="text-gray-600 mb-6">{product.stock}</p>
            </div>

            {Number(product.stock) > 0 && (
              <>
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Cantidad:</h3>
                <div className="mb-6 flex items-center justify-center w-28 border border-gray-300 rounded-lg">
                  <button
                    onClick={handleDecrement}
                    className="w-8 h-8 flex items-center justify-center text-gray-600 hover:text-black">
                    -
                  </button>
                  <span className="w-8 h-8 flex items-center justify-center text-gray-800 font-medium">
                    {quantity}
                  </span>
                  <button
                    onClick={() => handleIncrement(product.stock)}
                    className="w-8 h-8 flex items-center justify-center text-gray-600 hover:text-black">
                    +
                  </button>
                </div>
              </>
            )}

            {product?.handcraft ? (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Tiempo de entrega:</h3>
                <div className="flex flex-col gap-3">
                  <p className="text-gray-600 mb-6 w-full max-w-screen-md break-words">
                    Nuestros productos son elaborados 100% a mano, con amor y dedicación. Es por eso, el tiempo de entrega puede tardar hasta 15 días hábiles luego de la compra ¡pero te garantizamos que valdrá la pena!
                  </p>
                </div>
              </div>
            ) : (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Tiempo de entrega:</h3>
                <div className="flex flex-col gap-3">
                  <p className="text-gray-600 mb-6 w-full max-w-screen-md break-words">
                    ¡Recibe tus productos favoritos hasta la puerta de tu casa! El tiempo de entrega puede durar desde 5 hasta 24 horas. Recuerda que puedes verificar el estado de tu pedido en nuestro rastreador de producto.
                  </p>
                </div>
              </div>
            )}

            {alertMessage && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                  <span className="w-96 sm:inline">{alertMessage}</span>
                  <button className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={closeAlert} >
                    <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <title>Cerrar</title>
                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                    </svg>
                  </button>
              </div>
            )}

            {Number(product.stock) > 0 && (
              <button onClick={handleBuyNow} className="w-full md:w-80 bg-[#EB773C] text-white font-bold py-3 px-6 shadow rounded-lg hover:bg-[#d46834] transition">
                Comprar este producto
              </button>
            )}
            
          </div>
        </div>
        ):(
          <div className="flex flex-col items-center justify-center text-center p-6 min-h-screen py-24 -mt-28 -mb-28">
            <div className='-mt-48 w-72 relative z-10'>
              <LottieAnimation type={'loading-product-detail'} loop={true} />
            </div>
            <h2 className="text-3xl font-bold mt-8 text-[#EB773C]">Cargando productos</h2>
          </div>
        )
      }
      
    </div>
  );
};

export default ProductDetail;
