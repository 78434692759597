import hero from '../../../assets/images/trackHeader.jpg';
import Heading from '../../../components/shared/Heading/Heading';

const HeaderSection = () => {
    return (
        <div className="space-y-10 mx-auto px-4">
          <div className="h-[350px] w-full overflow-hidden rounded-2xl">
            <img
            src={hero}
              alt="hero products"
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className='px-8 md:px-0'>
            <Heading className='text-[#EB773C]' desc='Realiza el seguimiento de tu pedido y descubre en qué etapa del proceso se encuentra. Nuestra prioridad es mantenerte informado en todo momento.' isMain>
                Track de producto
            </Heading>
          </div>
        </div>
      );
}

export default HeaderSection;