import React from "react";
import {
  FaClipboardCheck,
  FaHourglassHalf,
  FaTruck,
  FaBoxOpen,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";

const orderHistory = [
  {
    date: "24 Nov 2023",
    time: null,
    status: "Pedido recibido",
    description: "Productos entregados",
  },
  {
    date: "Hoy",
    time: null,
    status: "Procesando el pedido",
    description: "Productos en proceso de entrega",
  },
  {
    date: "23 Nov 2023",
    time: "15:15",
    status: "Listo para envío",
    description: "Productos en el almacén del transportista",
  },
  {
    date: "22 Nov 2023",
    time: "12:27",
    status: "En tránsito",
    description: "Productos entregados al transportista - DHL Express",
  },
  {
    date: "19 Nov 2023",
    time: "10:47",
    status: "Entregado",
    description: "Pago aceptado - Tarjeta de crédito VISA",
  },
  {
    date: "19 Nov 2023",
    time: "10:45",
    status: "Pedido realizado",
    description: "Pedido realizado - Recibo #647563",
  },
];

const iconMapping: Record<string, JSX.Element> = {
  "Pedido recibido": <FaClipboardCheck />,
  "Procesando el pedido": <FaHourglassHalf />,
  "Listo para envío": <FaBoxOpen />,
  "En tránsito": <FaTruck />,
  "Entregado": <FaCheckCircle />,
  "Cancelado": <FaTimesCircle />,
};

const ShipmentTracking: React.FC = () => {
  const validStatuses = [
    "Pedido recibido",
    "Procesando el pedido",
    "Listo para envío",
    "En tránsito",
    "Entregado",
    "Cancelado",
  ];

  const filteredHistory = orderHistory.filter((item) =>
    validStatuses.includes(item.status)
  );

  return (
    <div className="container mx-auto px-8 md:px-0 my-10">
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Order History */}
        <div className="lg:w-1/3 bg-gray-50 text-black rounded-lg shadow p-6">
          <h3 className="text-2xl font-bold mb-4">Historial del pedido</h3>
          <ul className="space-y-6 relative border-l-2 border-gray-300 pb-0 pl-6">
            {filteredHistory.map((item, index) => (
              <li key={index} className="relative flex gap-4 items-start">
                <div className="absolute -left-10 top-4 -translate-y-1/2 w-8 h-8 bg-white border border-gray-300 rounded-full flex items-center justify-center shadow-sm">
                  {iconMapping[item.status]}
                </div>
                <div>
                  <p className="text-sm font-medium">
                    {item.date} {item.time && `, ${item.time}`}
                  </p>
                  <p className="text-sm text-gray-400">{item.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Product List */}
        <div className="lg:w-2/3 bg-gray-50 rounded-lg shadow p-8">
          <h3 className="text-2xl font-bold mb-6">
            Seguimiento de la entrega del pedido{" "}
            <span className="text-[#EB773C]">#957684673</span>
          </h3>
          <ul className="space-y-6">
            {[{
              name: "Sistema All in One APPLE iMac (2023) mq3r3o/a",
              id: "BJ8364850",
              quantity: 1,
              price: 1499,
              imageUrl: "https://via.placeholder.com/80",
            }].map((item, index) => (
              <li
                key={index}
                className="flex items-center justify-between border-b pb-4 last:border-b-0"
              >
                <div className="flex items-center gap-4">
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="w-20 h-20 object-cover rounded-lg shadow-sm"
                  />
                  <div>
                    <p className="font-medium text-lg">{item.name}</p>
                    <p className="text-sm text-gray-500">ID del producto: {item.id}</p>
                  </div>
                </div>
                <div className="text-right">
                  <p className="font-semibold text-lg text-gray-800">
                    ${item.price.toLocaleString()}
                  </p>
                  <p className="text-sm text-gray-500">x{item.quantity}</p>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-8 border-t pt-6 space-y-4">
            <div className="flex justify-between">
              <p className="text-gray-600">Subtotal</p>
              <p className="font-medium text-gray-800">$1,499.00</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-600">Envío estimado</p>
              <p className="font-medium text-gray-800">$299.00</p>
            </div>
            <div className="flex justify-between text-xl font-bold mt-6">
              <p className="text-gray-800">Total</p>
              <p className="text-[#EB773C]">$1,798.00</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentTracking;
