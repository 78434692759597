import React from 'react';
import { Link } from 'react-router-dom';
import iProduct from '../Products/Products.props';


const ProductCard: React.FC<iProduct> = ({
  id,
  name,
  description,
  price,
  discountPrice,
  image,
  category,
  stock,
  color,
  size,
  isOffert = false,
  handcraft = false,
}) => {
  return (
    <div className="relative mt-10 lg:mt-0 max-w-96 md:w-full bg-[#EBE4DA] border border-gray-200 rounded-xl shadow-xl overflow-hidden">
        <div className='relative overflow-hidden h-[380px]'>
            <img className="h-full w-full object-fill object-center rounded-xl shadow" width={1000} height={1000} src={image[0].url} alt={name} />
            {isOffert && Number(discountPrice) > 0 && (
              <div className="w-32 h-8 absolute top-5 -right-7">
                <div className="h-full w-full bg-[#EB773C] shadow bg-opacity-90 text-white text-center leading-8 font-semibold transform rotate-45">
                  Promoción
                </div>
              </div>
            )}
            {handcraft && (
              <div className="absolute bottom-5 left-5 bg-[#EB773C] shadow bg-opacity-90 text-white text-xs font-semibold px-3 py-2 rounded-lg">
                Hecho a mano
              </div>
            )}
        </div>
        <div className="p-4">
            <div className="flex justify-between items-center mb-2">
              <h5 className="text-lg font-semibold text-gray-900">{name}</h5>
              <div className="flex items-center gap-2">
                {isOffert && Number(discountPrice) > 0 ? (
                  <>
                    <span className="text-lg font-bold text-[#EB773C]">C${discountPrice}</span>
                    <span className="text-md text-gray-500 line-through">C${price}</span>
                  </>
                ) : (
                  <span className="text-lg font-bold text-gray-900">C${price}</span>
                )}
              </div>
            </div>
            <p className="text-gray-600 text-sm mb-4 text-start">{description}</p>
            <Link to={`/product/${id}`} state={{ id, name, description, price, discountPrice, image, category, stock, size, color, isOffert, handcraft }}>
              <button className="w-full bg-[#262626] text-white font-semibold py-2 rounded-lg hover:bg-black">
                Ver producto
              </button>
            </Link>
        </div>
    </div>
  );
};

export default ProductCard;
