import React from 'react';
import aboutUs from '../../../assets/images/aboutUs.jpg';
import facebook from '../../../assets/images/facebook.svg';
import instagram from '../../../assets/images/instagram.svg';
import Heading from '../../../components/shared/Heading/Heading';

const AboutUs: React.FC = () => {
  return (
    <div className="container mx-auto p-6 mt-16 bg-white rounded-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col justify-center text-[#EB773C]">
          <Heading isMain className="mb-0">
            Nosotros
          </Heading>
          <p className="text-gray-600 mb-4 text-start text-xl">
            Priscom es una tienda online de Managua, Nicaragua, donde encontrarás una variedad de artículos de moda y tecnología, como ropa, zapatos, accesorios, audífonos y mucho más. Descubre productos de calidad y estilo, diseñados especialmente para tí.
          </p>
          <div className="flex gap-4 mt-4 justify-center">
            <a
              href="https://www.facebook.com/priscom.ni/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-5xl transition-transform duration-300 hover:scale-110"
            >
              <img src={facebook} className="h-14 w-14" alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/priscom.nic/profilecard/?igsh=MWtieTVtZ3kyN2NrYg=="
              target="_blank"
              rel="noopener noreferrer"
              className="text-5xl transition-transform duration-300 hover:scale-110"
            >
              <img src={instagram} className="h-14 w-14" alt="instagram" />
            </a>
          </div>
        </div>

        {/* Sección de imagen */}
        <div className='shadow-lg'>
          <img 
            src={aboutUs} 
            alt="Team working in the office" 
            className="w-full h-auto rounded-lg object-cover "
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
