import React, { useEffect, useState } from 'react';
import { fetchRecords, resolveCategory } from '../../../services/airtableService';

interface SidebarFiltersProps {
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
}

const SidebarFilters: React.FC<SidebarFiltersProps> = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  const [categories, setCategories] = useState<string[]>(['Todos los productos']);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setError(null);

        const products = await fetchRecords('Products');

        const categoryIds = Array.from(
          new Set(products.map((product) => product.fields.Category))
        );

        const resolvedCategories = await Promise.all(
          categoryIds.map(async (categoryId) => {
            const resolved = await resolveCategory(categoryId);
            return resolved.name;
          })
        );

        const hasPromotion = products.some(
          (product) =>
            product.fields.IsOffert === true &&
            Number(product.fields.DiscountPrice) > 0
        );

        if (hasPromotion) {
          resolvedCategories.push('Promoción');
        }

        setCategories(['Todos los productos', ...Array.from(new Set(resolvedCategories))]);
      } catch (error) {
        console.error('Error al cargar categorías:', error);
        setError('Hubo un error al cargar las categorías. Por favor, intenta nuevamente.');
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="top-28 lg:sticky container mx-auto px-8 md:px-0">
      <div className="divide-y divide-neutral-300">
        <div className="relative flex flex-col space-y-4 pb-8">
          <h3 className="text-[#262626] mb-2.5 font-medium">Categorías</h3>

          {error ? (
            <p className="text-red-500 text-sm">{error}</p>
          ) : (
            <div className="hiddenScrollbar grid grid-cols-1 gap-5 overflow-y-hidden">
              {categories.map((category) => (
                <button
                  key={category}
                  className={`relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6 ${
                    selectedCategory === category
                      ? 'bg-[#EB773C] text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarFilters;
