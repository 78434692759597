import React, { useEffect } from 'react';
import HeaderSection from './HeaderSection';
import '../../styles/index.css';
import FeaturedProducts from './FeaturedProducts';
import AboutUs from './AboutUs';
import ContactForm from './ContactForm';
import SectionSlider from './SectionSlider';
import { AirtableRecord, fetchRecords, resolveCategory } from '../../services/airtableService';
import iProduct from '../../components/shared/Products/Products.props';

const Home = () => {
  const [products, setProducts] = React.useState<iProduct[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records: AirtableRecord[] = await fetchRecords("Products");

        const formattedProducts = await Promise.all(
          records.map(async (record) => {
            const resolvedCategory = record.fields.Category
              ? await resolveCategory(record.fields.Category)
              : { id: "", name: "Sin categoría" };

            return {
              id: record.id,
              name: record.fields.Name,
              description: record.fields.Description,
              price: record.fields.Price,
              discountPrice: record.fields.DiscountPrice,
              image: record.fields.Image,
              category: resolvedCategory,
              stock: record.fields.Stock,
              color: record.fields.Color,
              size: record.fields.Size,
              isOffert: record.fields.IsOffert,
              handcraft: record.fields.Handcraft,
            };
          })
        );

        setProducts(formattedProducts);
      } catch (error) {
        console.error("Error al buscar productos:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderSection />
      <SectionSlider />
      <FeaturedProducts products={products} />
      <AboutUs />
      <ContactForm />
    </>
  );
};

export default Home;
