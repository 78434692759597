import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { phoneMasks } from './phoneMasks';
import React, { useEffect } from 'react';

interface PhoneNumberInputProps {
  value?: string;
  onChange: (phone: string) => void;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  defaultCountry?: string;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value = '',
  onChange,
  placeholder,
  error = false,
  errorText = 'Número de teléfono no válido.',
  defaultCountry,
}) => {
  const [phone, setPhone] = React.useState(value);
  const [isValid, setIsValid] = React.useState(!error);

  useEffect(() => {
    setIsValid(!error);
  }, [error]);

  const handleOnChange = (value: string, country: any) => {
    setPhone(value);

    const phoneWithoutDialCode = value
      .replace(new RegExp(`^\\+?${country.dialCode}`), '')
      .replace(/[^0-9]/g, '');

    const countryCode = country.countryCode.toLowerCase();
    const mask = phoneMasks[countryCode];

    const expectedLength = (mask.match(/\./g) || []).length;

    if (phoneWithoutDialCode.length === expectedLength) {
      setIsValid(true);
      onChange(value);
    } else {
      setIsValid(false);
    }
  };

  return (
    <div className="relative w-full" style={{ width: '99.98%' }}>
      <PhoneInput
        value={phone}
        onChange={handleOnChange}
        placeholder={placeholder || 'Ingrese su número de teléfono'}
        containerStyle={{ width: '99.98%' }}
        inputStyle={{
          width: '99.98%',
        }}
        dropdownStyle={{ width: 'max-content' }}
        country={defaultCountry}
        masks={phoneMasks}
        inputProps={{
          name: 'phone',
        }}
        inputClass={`py-5 px-3 border rounded-lg focus:ring-2 focus:ring-orange-400 focus:outline-none w-full ${
          !isValid ? 'border-red-500' : 'border-gray-300'
        }`}
        isValid={() => isValid}
      />
      {!isValid && (
        <p className="text-red-500 text-sm">{errorText}</p>
      )}
    </div>
  );
};

export default PhoneNumberInput;
