import heroImage from '../../../assets/images/girl.png';
import logo from '../../../assets/images/Logos-09.svg';
import { useNavigate } from 'react-router-dom';

const HeaderSection = () => {
  const navigate = useNavigate();
  return (
    <div className="container px-2">
      <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-center lg:justify-between rounded-3xl bg-[#EB773C] px-4">
        <div className="basis-[50%] space-y-5 md:space-y-10 py-10 text-center lg:text-left text-white lg:pl-10">
          <img 
            src={logo} 
            alt="Priscom" 
            className="h-auto w-[200px] mx-auto lg:mx-0"/>
          <div className="text-[30px] md:text-[40px] lg:text-[60px] xl:text-[70px] font-bold" style={{ lineHeight: '1.1em' }}>
            Transformamos tu experiencia de compra con lo mejor en moda, electrónica y calzado.
          </div>
          <div className="flex justify-center lg:justify-start">
            <button
              onClick={() => navigate('/store')}
              className="flex items-center gap-1 border-2 border-black bg-black text-white text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6 rounded-full">
              Ver productos
            </button>
          </div>
        </div>

        <div className="flex basis-[47%] items-end overflow-hidden">
          <img
            src={heroImage}
            alt="hero"
            className="-mb-16 w-full object-bottom"/>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
