import { useNavigate } from "react-router-dom";
import LottieAnimation from "../../../components/shared/LottieAnimation";
import { useEffect } from "react";

const Error = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center text-center p-6 py-24">
      <div className="-mt-10 w-full max-w-[17rem] h-auto mb-6">
        <LottieAnimation type="error" loop={false} />
      </div>

      <h2 className="text-3xl font-bold text-gray-800 mb-4">
        Ocurrió un problema con tu compra
      </h2>

      <p className="text-lg text-gray-600 font-medium max-w-lg leading-relaxed mb-6">
        No pudimos procesar tu pedido en este momento. Esto puede deberse a un problema técnico o un error en el pago. Por favor, inténtalo nuevamente o contáctanos para ayuda.
      </p>

      <button
        onClick={() => navigate('/')}
        className="px-8 py-3 bg-[#EB773C] text-white text-lg font-semibold rounded-lg hover:bg-[#d46834] transition-transform transform hover:scale-105"
      >
        Volver al inicio
      </button>
    </div>
  );
};

export default Error;
