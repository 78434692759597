import React, { useEffect, useState } from 'react';
import HeaderSection from './HeaderSection';
import { FiSearch } from 'react-icons/fi';
import ShipmentTracking from './ShipmentTracking';

const Track: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [trackingNumber, setTrackingNumber] = useState<string>('');

  const handleSearch = () => {
    if (!trackingNumber.trim()) {
      alert('Por favor, ingrese un número de rastreo válido.');
      return;
    }
    console.log('Buscando número de rastreo:', trackingNumber);
  };

  return (
    <div className="container mb-20">
      <div className="mb-10">
        <HeaderSection />
      </div>
      <div className='px-8 md:px-0'>
        <div className='bg-gray-50 shadow py-5 px-6 rounded-lg'>
          <label htmlFor="trackNumber" className="text-sm font-medium text-gray-700">
            Número de rastreo
          </label>
          <div className="relative flex flex-col lg:flex-row items-center">
            <input
              id="trackNumber"
              type="text"
              minLength={5}
              placeholder="Ingrese el número de rastreo"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              className="w-full border rounded-lg p-2 focus:ring-2 focus:ring-orange-400 focus:outline-none mt-2"
            />
            <button
              onClick={handleSearch}
              disabled={!trackingNumber.trim()}
              className={`mt-4 lg:mt-2 lg:ml-4 flex items-center px-4 py-3 rounded-lg transition ${
                trackingNumber.trim()
                  ? 'bg-[#EB773C] text-white hover:bg-[#d46834]'
                  : 'bg-gray-300 text-gray-600 cursor-not-allowed'
              }`}
            >
              <FiSearch className="mr-2 text-xl" />
              Buscar
            </button>
          </div>
        </div>
      </div>
      <div className="mt-10 invisible">
        <ShipmentTracking />
      </div>
    </div>
  );
};

export default Track;
