'use client';

import type { Options } from '@splidejs/react-splide';
import { SplideSlide } from '@splidejs/react-splide';
import AutoScrollSlider from './AutoScrollSlider';

const newItemsData = [
    {
      coverImage:
        'https://images.unsplash.com/photo-1613994518041-8cd73527ecc2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Electrónica',
      year: null,
    },
    {
      coverImage:
        'https://images.unsplash.com/photo-1714729382668-7bc3bb261662?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Moda',
      year: null,
    },
    {
      coverImage:
        'https://images.unsplash.com/photo-1604136172276-0f0a615e7fae?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Calzado',
      year: null,
    },
    {
      coverImage:
        'https://images.unsplash.com/photo-1483985988355-763728e1935b?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Tendencias',
      year: null,
    },
  ];

const SectionSlider = () => {
  const sliderOptions: Options = {
    autoScroll: {
      speed: 0.7,
    },
    arrows: false,
    perPage: 5,
    type: 'loop',
    drag: 'free',
    pagination: false,
    gap: '50px',
    breakpoints: {
      640: {
        perPage: 2,
        gap: '10px',
      },
      768: {
        perPage: 2,
        gap: '20px',
      },
      1024: {
        perPage: 3,
        gap: '40px',
      },
      1280: {
        perPage: 4,
        gap: '40px',
      },
      1536: {
        perPage: 4,
        gap: '40px',
      },
    },
  };

  return (
    <div className="border-y border-neutral-300 py-10 mt-16">
      <AutoScrollSlider trigger options={sliderOptions}>
        {newItemsData.map((item) => (
          <SplideSlide key={item.title}>
            <div className="flex items-center gap-4">
              <div className="h-12 w-32 overflow-hidden rounded-lg">
                <img
                  src={item.coverImage}
                  alt="cover"
                  width={500}
                  height={500}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <p className="font-medium">
                {item.title}{' '}
                {item.year !== null ? (
                  <span className="text-neutral-500">{item.year}</span>
                ) : null}
              </p>
            </div>
          </SplideSlide>
        ))}
      </AutoScrollSlider>
    </div>
  );
};

export default SectionSlider;
