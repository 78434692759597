import React, { useEffect, useState } from 'react';
import HeaderSection from './HeaderSection';
import SidebarFilters from './SideBarFilter';
import SortBy from './SortBy';
import Products from './Products';

const Store: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [itemCount, setItemCount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<string>('Todos los productos');
  const [sortOrder, setSortOrder] = useState<string | null>(null);

  return (
    <div className="container mb-20">
      <div className="mb-10">
        <HeaderSection />
      </div>
      <div className="relative flex flex-col lg:flex-row" id="body">
        {/* Sidebar with filters */}
        <div className="pr-4 lg:basis-1/3 xl:basis-1/4">
          <SidebarFilters 
            selectedCategory={selectedCategory} 
            setSelectedCategory={setSelectedCategory} 
          />
        </div>
        <div className="mb-10 shrink-0 border-t lg:mx-4 lg:mb-0 lg:border-t-0" />
        <div className="relative flex-1 px-4 md:px-0">
          <div className="mb-5 flex items-center justify-between">
            <SortBy onSortChange={setSortOrder} />
            <span className="text-sm text-[#262626]">{itemCount} items</span>
          </div>
          <div className="grid flex-1 gap-7 sm:grid-cols-2 xl:grid-cols-3 2xl:gap-6 justify-center">
            <Products 
              onItemCount={setItemCount} 
              selectedCategory={selectedCategory} 
              sortOrder={sortOrder} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
