import React, { useEffect } from 'react';
import Heading from '../../components/shared/Heading/Heading';
import pattern from '../../assets/images/background.svg';

const TermsOfService: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="free-bird">
      <div className="bg-[#808BC3] edge-header mb-4" style={{
              backgroundColor: '#808BC3',
              backgroundImage: `url(${pattern})`,
              backgroundSize: '1000px',
              backgroundRepeat: 'repeat'
          }}></div>
      <div className="container free-bird mb-20 px-8 md:px-0">
        <div className="row">
          <div className="bg-gray-50 p-8 md:p-12 md:px-20 rounded-lg shadow-lg">
            <Heading className="font-bold text-[#EB773C] mb-6" isCenter isMain>
              Términos y Condiciones de Uso
            </Heading>
            <div className="text-gray-700 text-base md:text-lg leading-relaxed space-y-6">
              <p>
                Bienvenido/a a nuestra tienda online Priscom Nicaragua. Al realizar una compra en nuestro sitio, aceptas los siguientes términos y condiciones. Te recomendamos leerlos detenidamente antes de realizar cualquier pedido.
              </p>
              <p className="font-bold">1. Generalidades:</p>
              <p>1.1. Nuestro sitio web está destinado a ofrecer productos de calidad en categorías como ropa, calzado, electrónica, accesorios y más.</p>
              <p>1.2. Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento sin previo aviso. Es responsabilidad del cliente revisarlos periódicamente.</p>

              <p className="font-bold">2. Proceso de Compra:</p>
              <p>2.1. El cliente es responsable de proporcionar información completa y precisa al momento de realizar un pedido.</p>
              <p>2.2. Todas las compras están sujetas a disponibilidad de inventario. En caso de no contar con el producto solicitado, se notificará al cliente para coordinar una solución.</p>

              <p className="font-bold">3. Tiempos de Entrega:</p>
              <p>3.1. Los tiempos de entrega varían según el producto:</p>
              <ul className="list-disc list-inside">
                <li>Productos en stock: 5 a 24 horas.</li>
                <li>Productos hechos a mano o bajo pedido: hasta 15 días hábiles.</li>
              </ul>
              <p>3.2. Los tiempos de entrega son aproximados y pueden variar según la ubicación y condiciones externas.</p>

              <p className="font-bold">4. Políticas de Devoluciones:</p>
              <p>Entendemos que pueden surgir inconvenientes con los productos; sin embargo, nuestras políticas de devoluciones son las siguientes:</p>
              <ul className="list-disc list-inside">
                <li><span className="font-semibold">Ropa y Calzado:</span> No aceptamos devoluciones por errores en tallas. Verifica las medidas antes de realizar tu compra.</li>
                <li><span className="font-semibold">Electrónicos:</span> No se aceptarán devoluciones una vez abierto el empaque, salvo fallas técnicas comprobables dentro de 3 días hábiles tras la entrega.</li>
                <li><span className="font-semibold">Otros Productos:</span> Las devoluciones solo serán aceptadas en caso de defectos comprobables dentro de 2 días hábiles.</li>
              </ul>

              <p className="font-bold">5. Garantías:</p>
              <p>5.1. Los productos electrónicos cuentan con garantía limitada de 15 días para defectos de fábrica. No incluye daños causados por uso inadecuado.</p>
              <p>5.2. Es necesario conservar el comprobante de compra para cualquier reclamación.</p>

              <p className="font-bold">6. Pagos y Reembolsos:</p>
              <p>6.1. Todos los pagos deben realizarse a través de los métodos disponibles en nuestra plataforma, incluyendo Pagadito.</p>
              <p>6.2. Los reembolsos se realizarán solo si no es posible reemplazar el producto y están sujetos a los términos mencionados.</p>

              <p className="font-bold">7. Seguridad en la Información de Pago:</p>
              <p>7.1. Utilizamos cifrado SSL para proteger tu información. No almacenamos información confidencial de tarjetas de crédito o débito.</p>

              <p className="font-bold">8. Webhooks de Pagadito:</p>
              <p>8.1. Utilizamos webhooks de Pagadito para garantizar la confirmación de las órdenes. Esto permite la sincronización automática en nuestra base de datos.</p>

              <p className="font-bold">9. Limitación de Responsabilidad:</p>
              <p>9.1. No nos hacemos responsables por retrasos debido a causas fuera de nuestro control, como desastres naturales o problemas logísticos externos.</p>

              <p className="font-bold">10. Contacto:</p>
              <p>Si tienes alguna duda, puedes contactarnos a través de nuestro correo electrónico o número de atención al cliente, disponibles en nuestra sección de contacto.</p>

              <p className="italic">Al realizar una compra, aceptas haber leído y entendido estos términos y condiciones.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
