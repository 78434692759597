import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LottieAnimation from "../../../components/shared/LottieAnimation";

const Thanks = () => {
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const verifyPayment = async () => {
      const params = new URLSearchParams(window.location.search);
      const transactionId = params.get("transaction_id"); // ID de la transacción

      if (!transactionId) {
        setPaymentStatus("failed"); // Si no hay transacción, marcar como fallida
        return;
      }

      try {
        // Simular llamada para verificar la transacción
        const response = await fetch(`/api/verify-payment?transaction_id=${transactionId}`);
        const data = await response.json();

        if (data.status === "success") {
          setPaymentStatus("success");
        } else {
          setPaymentStatus("failed");
        }
      } catch (error) {
        console.error("Error verificando el pago:", error);
        setPaymentStatus("failed");
      }
    };

    verifyPayment();
  }, []);

  if (paymentStatus === null) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-lg font-semibold text-gray-700">Verificando tu transacción...</p>
      </div>
    );
  }

  if (paymentStatus === "failed") {
    return (
      <div className="flex flex-col items-center justify-center text-center p-6 py-24">
        <h2 className="text-3xl font-bold text-red-600 mb-4">Error en la Transacción</h2>
        <p className="text-lg text-gray-600 font-medium max-w-lg leading-relaxed text-center">
          Hubo un problema con tu pago. Por favor, inténtalo de nuevo o contáctanos para más ayuda.
        </p>
        <button
          onClick={() => navigate('/checkout')}
          className="mt-6 px-8 py-3 bg-[#EB773C] text-white text-lg font-semibold rounded-lg hover:bg-[#d46834] transition-transform transform hover:scale-105"
        >
          Volver al Checkout
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center text-center p-6 py-24">
      <div className="-mt-10 w-full max-w-lg relative z-10">
        <LottieAnimation type="thanks" loop={false} />
      </div>

      <h2 className="text-3xl font-bold text-gray-800 mb-4 -mt-10">
        ¡Gracias por tu compra!
      </h2>

      <p className="text-lg text-gray-600 font-medium max-w-lg leading-relaxed text-center">
        Hemos recibido tu pedido con éxito. Recibirás una confirmación en tu correo. <br /> ¡Gracias
        por confiar en nosotros!
      </p>

      <button
        onClick={() => navigate('/track')}
        className="mt-6 px-8 py-3 bg-[#EB773C] text-white text-lg font-semibold rounded-lg hover:bg-[#d46834] transition-transform transform hover:scale-105"
      >
        Seguimiento de pedido
      </button>
    </div>
  );
};

export default Thanks;
